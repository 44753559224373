/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-return */
declare interface DataLayerWindow extends Window {
  dataLayer: any;
}
declare const window: DataLayerWindow;

const track = (event) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({ ecommerce: null });
  window.dataLayer.push(event);

  if (process.env.NODE_ENV === "development") {
    console.log("[TRACK]", event);
  }
};

export const trackCheckout = (order, organization) => {
  track({
    event: "begin_checkout",
    ecommerce: {
      transaction_id: order?.orderNumber,
      affiliation: "Flicket Online Store",
      value: order?.total,
      tax: order?.totalTax,
      shipping: order?.deliveryFee,
      currency: organization?.currency,
      coupon: order?.promoCode?.code,
      items: order?.lineItems?.edges
        ?.filter(({ node }) => node?.total > 0)
        ?.map(({ node }) => ({
          item_name: node?.name,
          item_id:
            node.ticketType?.id ||
            node.membershipType?.id ||
            node.eventAddon?.id ||
            node.membershipAddon?.id,
          price: node?.price,
          item_price: node?.price,
          item_category: node?.type,
          quantity: node?.quantity,
        })),
    },
    customerDetails: {
      billing_first_name: order.user ? order.user.firstName : null,
      billing_last_name: order.user ? order.user.lastName : null,
      billing_email: order.user ? order.user.email : null,
      billing_phone: order.user ? order.user.phoneNumber : null,
      billing_postal_code: order.user
        ? order.user.shippingAddress?.postalCode
        : null,
      billing_state: order.user ? order.user.shippingAddress?.postalCode : null,
      billing_city: order.user ? order.user.shippingAddress?.city : null,
      billing_country: order.user ? order.user.shippingAddress?.country : null,
    },
  });
};

export const trackTransactionReady = (order) => {
  track({
    event: "transactionReady",
    transactionId: order?.orderNumber,
    transactionAffiliation: order?.event?.title || order?.membership?.name,
    transactionTotal: order?.total,
    transactionTax: order?.totalTax,
    transactionProducts: order?.lineItems?.edges
      ?.filter(({ node }) => node?.total > 0)
      ?.map(({ node: lineItem }) => ({
        sku:
          lineItem.ticketType?.id ||
          lineItem.membershipType?.id ||
          lineItem.eventAddon?.id ||
          lineItem.membershipAddon?.id,
        name: lineItem.name,
        category: lineItem.type,
        item_category: lineItem.type,
        item_price: lineItem?.price,
        price: lineItem.price,
        quantity: lineItem.quantity,
      })),
  });
};

export const trackPurchase = (order, organization) => {
  track({
    event: "purchase",
    ecommerce: {
      transaction_id: order?.orderNumber,
      affiliation: order?.event?.title || order?.membership?.name,
      value: order?.total,
      tax: order?.totalTax,
      shipping: order?.deliveryFee,
      currency: organization?.currency,
      items: order?.lineItems?.edges
        ?.filter(({ node }) => node?.total > 0)
        ?.map(({ node }) => ({
          item_name: node?.name,
          item_id:
            node.ticketType?.id ||
            node.membershipType?.id ||
            node.eventAddon?.id ||
            node.membershipAddon?.id,
          item_price: node?.price,
          price: node?.price,
          category: node?.type,
          item_category: node?.type,
          quantity: node?.quantity,
        })),
      customerDetails: {
        billing_first_name: order.user ? order.user.firstName : null,
        billing_last_name: order.user ? order.user.lastName : null,
        billing_email: order.user ? order.user.email : null,
        billing_phone: order.user ? order.user.phoneNumber : null,
        billing_postal_code: order.user
          ? order.user.shippingAddress?.postalCode
          : null,
        billing_state: order.user
          ? order.user.shippingAddress?.postalCode
          : null,
        billing_city: order.user ? order.user.shippingAddress?.city : null,
        billing_country: order.user
          ? order.user.shippingAddress?.country
          : null,
      },
    },
  });
};

export const trackSelectItem = (item) => {
  track({
    event: "select_item",
    ecommerce: {
      items: [
        {
          item_id: item.id,
          item_list_name: item.name,
          quantity: item.quantity,
          price: item.price,
        },
      ],
    },
  });
};

export const trackAddToCart = (item) => {
  track({
    event: "add_to_cart",
    ecommerce: {
      items: [
        {
          item_id: item.id,
          item_list_name: item.name,
          quantity: item.quantity,
          price: item.price,
        },
      ],
    },
  });
};

export const trackRemoveFromCart = (item) => {
  track({
    event: "remove_from_cart",
    ecommerce: {
      items: [
        {
          item_id: item.id,
          item_list_name: item.name,
          quantity: item.quantity,
          price: item.price,
        },
      ],
    },
  });
};

//not working as intended, due to multiple page reloads
export const trackViewItemList = (zones, ticketTypes) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const mappedTickets = zones?.map((zone) =>
    ticketTypes?.map((ticketType) => {
      const zoneTypes = zone?.ticketTypes ?? zone?.membershipTypes;
      const zoneTicketType = zoneTypes?.find(
        ({ ticketTypeId, membershipTypeId }) =>
          ticketTypeId === ticketType.id || membershipTypeId === ticketType.id
      );

      if (!zoneTicketType || !zone.isActive || !zoneTicketType.isActive) {
        return null;
      }

      return {
        name: `${zone?.name ?? ""} - ${ticketType?.name ?? ""}`,
        price: zoneTicketType.price,
        zoneId: zone?.name,
        ticketTypeId: ticketType.id,
      };
    })
  );

  let items = [];
  mappedTickets?.forEach((element) => {
    element.forEach((element2) => {
      items.push(element2);
    });
  });
  items = items.filter((item) => item?.name.length > 0);

  track({
    event: "view_item_list",
    ecommerce: {
      items: items.map((item) => ({
        item_id: item.ticketTypeId,
        item_name: item.name,
        item_list_name: item.name,
        price: item.price,
      })),
    },
  });
};
